import React, { useState, useEffect } from "react"
import { graphql, useStaticQuery } from "gatsby"
import "../styles/global.css"
import { Link as GatsbyLink } from "gatsby"
import { Link as ChakraLink, Flex } from "@chakra-ui/core"
import Layout from "../components/layout"
// import {
//   Button,
//   Popover,
//   PopoverTrigger,
//   PopoverContent,
//   PopoverBody,
//   PopoverArrow,
//   PopoverCloseButton,
//   useDisclosure,
// } from "@chakra-ui/core"
import { DistortionText } from "react-text-fun"
// @ts-ignore

// import iframely from "@iframely/embed.js"

import {
  // Modal,
  ModalOverlay,
  // ModalContent,
  // ModalHeader,
  // ModalFooter,
  // ModalBody,
  // ModalCloseButton,
} from "@chakra-ui/core"

export const query = graphql`
  query($id: Int!) {
    strapiWork(strapiId: { eq: $id }) {
      title
      slug
      # published
      column_left
      column_right
      column_left_en
      column_right_en
      date_of_work
      right_background_black
      main_tag {
        name
        id
      }
      tags {
        id
        name
        slug
      }
    }
    allStrapiTag {
      edges {
        node {
          slug
          name
          # strapiId
          # works {
          #   title
          #   slug
          # }
        }
      }
    }
  }
`

function Work({ data }) {
  // console.log({ data })

  // const { isOpen, onOpen, onClose } = useDisclosure()
  const [fullScreen, steFullScreen] = React.useState(false)
  //
  const [mobile, setMobile] = React.useState(false)
  useEffect(() => {
    window.innerWidth > 900 ? setMobile(true) : setMobile(false)
  }, [])
  const [enIsSet, setEnIsSet] = React.useState("EN")

  const work = data.strapiWork
  const split = work.title.split(" ")
  // React.useEffect(() => {
  //   console.log("useEffect")
  //   const figures = document.querySelectorAll("figure.image")
  //   figures.forEach(figure => {
  //     console.log(figure.childNodes[0].className)
  //   })
  // })
  React.useEffect(() => {
    // setTimeout(() => {
    const figures = document.querySelectorAll("figure.image")
    // console.log(figures)
    figures.forEach(figure => {
      // console.log(figure)
      figure.addEventListener("click", function () {
        // console.log(this)
        if (this.className === "image") {
          // if (this.nodeName === "FIGURE") {
          //   // console.log("this.className === 'image'")
          this.className = "image fullscreen"
          this.children[0].className = "img-fullscreen"
        }
        // }
        // else if (this.nodeName === "FIGURE") {
        //   this.className = "image fullscreen"
        //   this.children[0].className = "img-fullscreen"
        // }
        else {
          this.className = "image"
          this.children[0].className = ""
        }
      })
    })
    // }, 1500)
  }, [])

  React.useEffect(() => {
    document.querySelectorAll("oembed[url]").forEach(element => {
      // Create the <a href="..." class="embedly-card"></a> element that Embedly uses
      // to discover the media.
      const iframe = document.createElement("iframe")
      const src = element.getAttribute("url")
      // console.log({ src })
      const splited = src.split("/")
      // console.log({ splited })
      const vimeoNumber = splited[3]

      // iframe.setAttribute("src", element.getAttribute("url"))
      iframe.setAttribute(
        "src",
        `https://player.vimeo.com/video/${vimeoNumber}?title=0&byline=0&portrait=0&sidedock=0&byline=0&portrait=0&transparent=1`
      )
      iframe.setAttribute("width", "640")
      iframe.setAttribute("height", "1138")
      iframe.setAttribute("frameborder", "0")
      iframe.setAttribute("allow", "fullscreen")
      iframe.setAttribute("allowfullscreen", "")
      // anchor.className = "embedly-card"
      /* <iframe src="https://player.vimeo.com/video/448617702" width="640" height="360" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
<p><a href="https://vimeo.com/448617702">Love Me, Fear Me</a> from <a href="https://vimeo.com/user1660976">Veronica Solomon</a> on <a href="https://vimeo.com">Vimeo</a>.</p> */
      element.appendChild(iframe)
    })
  }, [])

  useEffect(() => {
    setTimeout(() => {
      // var isInViewport = function (elem) {
      //   var bounding = elem.getBoundingClientRect()
      //   return (
      //     bounding.top >= 0 &&
      //     bounding.left >= 0 &&
      //     bounding.bottom <=
      //       (window.innerHeight || document.documentElement.clientHeight) &&
      //     bounding.right <=
      //       (window.innerWidth || document.documentElement.clientWidth)
      //   )
      // }

      // const gambitGalleryIsInView = el => {
      //   const scroll = window.scrollY || window.pageYOffset
      //   const scroll = window.scrollY || window.pageYOffset
      //   const boundsTop = el.getBoundingClientRect().top + scroll

      //   const viewport = {
      //     top: scroll,
      //     bottom: scroll + window.innerHeight,
      //   }

      //   const bounds = {
      //     top: boundsTop,
      //     bottom: boundsTop + el.clientHeight,
      //   }

      //   return (
      //     (bounds.bottom >= viewport.top && bounds.bottom <= viewport.bottom) ||
      //     (bounds.top <= viewport.bottom && bounds.top >= viewport.top)
      //   )
      // }
      let leftCol = document.querySelector("#column__left")
      let rightCol = document.querySelector("#column__right")
      console.log({ leftCol, rightCol })

      const leftBlocs = leftCol.querySelectorAll("blockquote")
      const rightBlocs = rightCol.querySelectorAll("blockquote")
      console.log({ leftBlocs, rightBlocs })

      // const scroll = leftCol.scrollY || leftCol.pageYOffset
      // if (isInViewport(leftBlocs[0])) {
      //   console.log("isInViewport")
      // }
      const vw = Math.max(
        document.documentElement.clientWidth || 0,
        window.innerWidth || 0
      )
      const vh = Math.max(
        document.documentElement.clientHeight || 0,
        window.innerHeight || 0
      )
      function handleLeftColScroll() {
        // console.log("leftCol scroll")
        leftBlocs.forEach(quoteEl => {
          const top = quoteEl.getBoundingClientRect().top
          // console.log({ quoteEl, top })
          if (top <= vh && top >= 0 && top <= top + vh) {
            // console.log("quoteEl is inside viewport")
            let oppositePair
            rightBlocs.forEach(el => {
              if (el.textContent === quoteEl.textContent) {
                oppositePair = el
                console.log({ oppositePair })
              }
            })
            if (
              oppositePair.getBoundingClientRect().top <= vh &&
              oppositePair.getBoundingClientRect().top >= 0 &&
              oppositePair.getBoundingClientRect().top <=
                oppositePair.getBoundingClientRect().top + vh
            ) {
              // console.log("already inside viewport, do nothing")
            } else {
              // console.log("scroll to element")
              oppositePair.scrollIntoView({
                behavior: "smooth",
              })
            }
          }
        })
      }

      function handleRightColScroll() {
        // console.log("rightScroll scroll")
        rightBlocs.forEach(quoteEl => {
          const top = quoteEl.getBoundingClientRect().top
          // console.log({ quoteEl, top })
          if (top <= vh && top >= 0 && top <= top + vh) {
            // console.log("quoteEl is inside viewport")
            let oppositePair
            leftBlocs.forEach(el => {
              if (el.textContent === quoteEl.textContent) {
                oppositePair = el
                console.log({ oppositePair })
              }
            })
            if (
              oppositePair.getBoundingClientRect().top <= vh &&
              oppositePair.getBoundingClientRect().top >= 0 &&
              oppositePair.getBoundingClientRect().top <=
                oppositePair.getBoundingClientRect().top + vh
            ) {
              // console.log("already inside viewport, do nothing")
            } else {
              // console.log("scroll to element")
              oppositePair.scrollIntoView({
                behavior: "smooth",
              })
            }
          }
        })
      }

      leftCol.addEventListener("scroll", handleLeftColScroll)
      // leftCol.removeEventListener("scroll", handleLeftColScroll)

      rightCol.addEventListener("scroll", handleRightColScroll)
      // rightCol.removeEventListener("scroll", handleRightColScroll)

      leftCol.addEventListener("mouseenter", () => {
        console.log("leftCol mouseenter")
        rightCol.removeEventListener("scroll", handleRightColScroll)
        leftCol.addEventListener("scroll", handleLeftColScroll)
      })

      rightCol.addEventListener("mouseenter", () => {
        console.log("rightCol mouseenter")
        leftCol.removeEventListener("scroll", handleLeftColScroll)
        rightCol.addEventListener("scroll", handleRightColScroll)
      })
    }, 1500)
  }, [])

  let tags = data.strapiWork.tags
  let mainTagObj
  let tagsReady

  if (data.strapiWork.main_tag) {
    mainTagObj = tags.filter(tag => tag.name === data.strapiWork.main_tag.name)

    tagsReady = tags.filter(tag => tag.name !== data.strapiWork.main_tag.name)

    mainTagObj[0]?.name?.toUpperCase()

    tagsReady.unshift(mainTagObj[0])

    // console.log({ tags, tagsReady }, mainTagObj[0])
  } else {
    tagsReady = tags
  }
  return (
    <>
      <div
        style={{
          position: "fixed",
          margin:
            "calc(1em - 2px) 0 0 calc(50vw + 30vw + (50vw - 30vw) / 2 - 60px)",
          width: "300px",
          fontSize: "16px",
          fontWeight: "bold",
          lineHeight: 1.5,
          zIndex: 9999,
          display: mobile ? (fullScreen ? "none" : "flex") : "none",
          flexDirection: "row",
          // opacity: fullScreen ? 0 : 1,
        }}
      >
      <button
          onClick={() => {
            console.log("FR")
            setEnIsSet(true)
          }}
          style={{
            // position: "fixed",
            // margin: "calc(1em - 2px) 0 0 72vw",
            fontSize: "16px",
            fontWeight: "bold",
            lineHeight: 1.5,
            // zIndex: 9999,
            opacity: enIsSet ? 0.5 : 1,
            paddingRight: "13px",
            // width: 40,
            // display: mobile ? "block" : "none",
           

          }}
        >
          EN
        </button>
        <button
          onClick={() => {
            console.log("FR")
            setEnIsSet(false)
          }}
          style={{

            fontSize: "16px",
            fontWeight: "bold",
            lineHeight: 1.5,
            // zIndex: 9999,
            // width: 40,
            opacity: !enIsSet ? 0.5 : 1,
          }}
        >
          FR
        </button>
      </div>
      <ModalOverlay
        className="modal-overlay-fullscreen"
        style={{ display: fullScreen ? "block" : "none" }}
      />
      <Layout>
        <main>
          <div id="column__left">
            <div style={{ paddingBottom: "3rem" }}>
              <h1
                className={"main-title-bold"}
                style={{
                  // fontSize: globalThis.innerWidth > 415 ? 60 : 30,
                  fontFamily: "Montserrat",
                  fontWeight: 900,
                }}
              >
                {data.strapiWork.title}
              </h1>

              {/* {split.map((word, i) => (
                <DistortionText
                  key={`dist-${word}-${i}`}
                  fontSize={globalThis.innerWidth > 415 ? 60 : 30}
                  fontWeight={900}
                  fill="black"
                  fontFamily="'Montserrat', 'sans-serif'"
                  text={word}
                  speed={0.00001}
                />
              ))} */}
            </div>
            <div>
              <h3 style={{ paddingBottom: "3rem" }}>
                {data?.strapiWork?.date_of_work}
              </h3>
            </div>
            <div className="tag-wrapper">
              {/* {data.strapiWork.main_tag.name.toUpperCase()} */}
              {tagsReady &&
                tagsReady
                  // .sort(tag => {
                  //   console.log({ tag })
                  //   return tag
                  // })
                  .map((tag, i) => {
                    // console.log({ tag })
                    return (
                      <div key={`tags-${tag?.id}`}>
                        <ChakraLink
                          // className="nav-link "
                          key={`link-tag-${tag?.slug}`}
                          style={{
                            fontFamily: "Montserrat",
                            paddingRight: 15,
                            paddingLeft: 30,
                            fontWeight: 400,
                          }}
                          // @ts-ignore
                          as={GatsbyLink}
                          to={`/${tag?.slug}`}
                        >
                          {i === 0 && data.strapiWork.main_tag
                            ? tag?.name.toUpperCase()
                            : tag?.name}
                        </ChakraLink>
                      </div>
                    )
                  })}
            </div>
            {/* {enIsSet ? <>FR</> : <>EN</>} */}
            <div
              key={`column__left-html`}
              id="column__left-html"
              dangerouslySetInnerHTML={{
                __html: enIsSet ? work.column_left_en : work.column_left,
              }}
              onClick={function (ev) {
                if (globalThis.innerWidth > 900) {
                  if (ev.target.className === "img-fullscreen") {
                    steFullScreen(true)
                  } else if (ev.target.className === "image fullscreen") {
                    steFullScreen(true)
                  } else if (ev.target.nodeName === "FIGCAPTION") {
                    steFullScreen(true)
                  } else {
                    steFullScreen(false)
                  }
                }
              }}
            />
          </div>
          <div
            id="column__right"
            style={{
              backgroundColor: data.strapiWork.right_background_black
                ? "black"
                : "white",
            }}
          >
            <div
              key={`column__right-html`}
              id="column__right-html"
              className={`${
                data.strapiWork.right_background_black ? "inverted" : "normal"
              }`}
              dangerouslySetInnerHTML={{
                __html: enIsSet ? work.column_right_en : work.column_right,
              }}
              onClick={function (ev) {
                if (globalThis.innerWidth > 900) {
                  if (ev.target.className === "img-fullscreen") {
                    steFullScreen(true)
                  } else if (ev.target.className === "image fullscreen") {
                    steFullScreen(true)
                  } else if (ev.target.nodeName === "FIGCAPTION") {
                    steFullScreen(true)
                  } else {
                    steFullScreen(false)
                  }
                }
              }}
            />
          </div>
        </main>
      </Layout>
    </>
  )
}

export default Work
